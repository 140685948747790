import { CONSENT_STATUS } from '../constants/CONSENT_STATUS';
import { COMMENT_PREFIX } from '../constants/GENERAL';
import { VENDOR_TYPE } from '../constants/VENDOR_TYPE';
import { WHITE_LIST_DOMAINS } from '../constants/WHITE_LIST_DOMAINS';
import { stubConfig } from '../services/api';
import { trackEvent } from '../services/event-logger';
import { isArraysEqual } from './array';
import { getAllowedHostListFromCookies, getAllowedHostListFromVendors, getConsentConfig } from './consent-config';
import { getHostFromUrl, isRestrictedInlinePresent, parseAllUrls } from './string';

export const handleAllCommentedNode = async (cb: (node: Comment) => Promise<void>) => {
  const nodeIterator = document.createNodeIterator(
    document.getElementsByTagName('html')[0],
    NodeFilter.SHOW_COMMENT,
    () => NodeFilter.FILTER_ACCEPT
  );

  while (nodeIterator.nextNode()) {
    const commentNode = nodeIterator.referenceNode as Comment;

    if ((commentNode.textContent || '').indexOf(COMMENT_PREFIX) !== -1 && commentNode.parentNode) {
      await cb(commentNode);
    }
  }
};

export const createElementFromHTML = (htmlString: string | null): HTMLElement | null => {
  if (htmlString) {
    const div = document.createElement('div');

    div.innerHTML = htmlString.replace(COMMENT_PREFIX, '').trim();

    return div.firstChild as HTMLElement;
  }

  return null;
};

export const recoveryElement = async (commentNode: Comment, force = false) => {
  const tmpElem = createElementFromHTML(commentNode.nodeValue);

  if (tmpElem == null) {
    return;
  }

  const newElem = document.createElement(tmpElem.localName);
  const elemAttributes = tmpElem.attributes;
  const { isHostRestricted, isNodeAllowed } = getNodeValidationData(tmpElem);
  let shouldWaitLoadSource = false;

  if ((isHostRestricted || !isNodeAllowed) && !force) {
    return;
  }

  if (
    tmpElem.localName === 'script' &&
    elemAttributes.hasOwnProperty('type') &&
    elemAttributes.hasOwnProperty('data-origin-type')
  ) {
    const originTypeAttr = elemAttributes.getNamedItem('data-origin-type');

    if (originTypeAttr?.value) {
      tmpElem.setAttribute('type', originTypeAttr?.value);
    } else {
      tmpElem.removeAttribute('type');
      tmpElem.removeAttribute('data-origin-type');
    }

    const asyncAttr = !!tmpElem.getAttribute('async');
    const deferAttr = !!tmpElem.getAttribute('defer');
    const srcAttr = !!tmpElem.getAttribute('src');

    shouldWaitLoadSource = srcAttr && !asyncAttr && !deferAttr;
  }

  for (const attrName of tmpElem.getAttributeNames()) {
    const attrValue = tmpElem.getAttribute(attrName);

    if (attrValue !== null) {
      newElem.setAttribute(attrName, attrValue);
    }
  }

  newElem.innerHTML = tmpElem.innerHTML;

  if (shouldWaitLoadSource) {
    // Wait for loading blocking script
    await new Promise((resolve) => {
      newElem.onload = resolve;

      commentNode.replaceWith(newElem);
    });
  } else {
    commentNode.replaceWith(newElem);
  }

  return newElem;
};

export const isNodeHostRestricted = (src: string | null, node?: HTMLElement) => {
  if (src) {
    const isRelativePath = !new RegExp('^(?:[a-z+]+:)?//', 'i').test(src);

    if (/^(chrome-extension|data):/.test(src)) {
      return { result: false, isRelativePath };
    }

    const config = window['AdlCmp'] && window['AdlCmp'].getSiteConfig();
    const hostFromUrl = getHostFromUrl(src);
    const consentConfig = getConsentConfig();
    let allowedHostList: string[] = [];
    const whiteListDomains = [
      ...WHITE_LIST_DOMAINS,
      ...(stubConfig?.wl_domains || []),
      ...(window['ADL_CMP_STUB_CONFIG']?.wl_domains || []),
      ...(window['AdlCmp']?.config?.white_list_domains || [])
    ].filter((item) => item);

    if (config) {
      const allowedCategoriesHostList = getAllowedHostListFromCookies(
        consentConfig.allowedCategories || [],
        config.cookies
      );
      const allowedVendorsHostList = getAllowedHostListFromVendors(
        consentConfig.allowedCategories || [],
        config.vendors_cookies || {},
        (consentConfig.allowedVendorsConsent && consentConfig.allowedVendorsConsent[VENDOR_TYPE.IAB]) || []
      );

      allowedHostList = [...allowedCategoriesHostList, ...allowedVendorsHostList];
    }

    const result = ![window.location.hostname, ...whiteListDomains, ...allowedHostList].some((re) => {
      try {
        return new RegExp(re).test(hostFromUrl);
      } catch (e) {
        trackEvent({ event_type: 'error', event_data: { statusText: e.message } });

        return false;
      }
    });

    return { result, isRelativePath: window.location.hostname === hostFromUrl, linkPresence: true };
  } else if (node && node.tagName === 'SCRIPT') {
    const scriptLinks = parseAllUrls(node.innerText);

    if (Array.isArray(scriptLinks) && scriptLinks.length) {
      return {
        result:
          scriptLinks.some((url) => isNodeHostRestricted(url).result || false) ||
          isRestrictedInlinePresent(node.innerText),
        isRelativePath: false,
        linkPresence: true
      };
    } else {
      return { result: isRestrictedInlinePresent(node.innerText), isRelativePath: false, linkPresence: false };
    }
  }

  return { result: null, isRelativePath: false };
};

export const getNodeValidationData = (node: HTMLElement) => {
  let nodeSrc = (node as HTMLImageElement).src;
  const dataOriginalAttr = node.getAttribute('data-original');
  const dataSrcAttr = node.getAttribute('data-src');
  const srcSetAttr = node.getAttribute('srcset');
  const consentConfig = getConsentConfig();
  const allCategoryCodeList = window['AdlCmp'] ? window['AdlCmp'].getAllCategoryCodeList() : [];

  if (
    consentConfig.status === CONSENT_STATUS.ACCEPTED_ALL ||
    (consentConfig.status === CONSENT_STATUS.ACCEPTED_PARTLY &&
      isArraysEqual(allCategoryCodeList, consentConfig.allowedCategories || []))
  ) {
    return { isHostRestricted: false, isNodeAllowed: true };
  }

  if (/^data:/.test(nodeSrc) || !nodeSrc) {
    if (dataOriginalAttr) {
      nodeSrc = dataOriginalAttr;
    } else if (dataSrcAttr) {
      nodeSrc = dataSrcAttr;
    } else if (srcSetAttr) {
      nodeSrc = srcSetAttr.split(',')[0];
    }
  }

  const { result: _isNodeHostRestricted } = isNodeHostRestricted(nodeSrc, node);
  const ignoreNode = node.getAttribute('data-adl-consent') === 'ignore';
  const isHostRestricted = _isNodeHostRestricted ? !ignoreNode : _isNodeHostRestricted;
  const isInlineScript = node.tagName === 'SCRIPT' && !nodeSrc;
  const isInlineIframe =
    node.tagName === 'IFRAME' &&
    (!nodeSrc || nodeSrc === 'about:blank') &&
    node.getAttribute('aria-label') !== 'Advertisement';

  const isNodeAllowed =
    (node.getAttribute('name') === '__tcfapiLocator' ||
      node.getAttribute('id') === '__NEXT_DATA__' ||
      ['text/x-jquery-tmpl', 'application/ld+json'].includes(node.getAttribute('type') as string) ||
      ignoreNode ||
      ((nodeSrc || isInlineScript) && !isHostRestricted) ||
      isInlineIframe) &&
    node.getAttribute('data-adl-consent') !== 'blocked';

  return { isHostRestricted, isNodeAllowed };
};
