import { ILoggerEventArg } from '../typings/logger-event';
import { AdlCmpStub } from './api';

export const trackEvent = (event: ILoggerEventArg) => {
  const data = JSON.stringify(event);
  const { hash } = AdlCmpStub.getScriptAttr();
  const endpoint = `event${hash ? `?hash=${hash}` : ''}`;

  fetch(`${KEEPER_URL}/${endpoint}`, {
    method: 'POST',
    referrerPolicy: 'unsafe-url',
    body: data
  });
};
