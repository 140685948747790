export class EventEmitter {
  events: Record<string, any[]>;

  constructor() {
    this.events = {};
  }

  subscribe(eventName: string, callback) {
    !this.events[eventName] && (this.events[eventName] = []);
    this.events[eventName].push(callback);

    return () => this.unsubscribe(eventName, callback);
  }

  unsubscribe(eventName: string, callback) {
    this.events[eventName] = this.events[eventName].filter((eventCallback) => callback !== eventCallback);
  }

  emit(eventName: string, ...args) {
    const event = this.events[eventName];

    event && event.forEach((callback) => callback.call(null, ...args));
  }
}
