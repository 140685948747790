import { CHECK_ELEMENT_LIST, COMMENT_PREFIX } from '../constants/GENERAL';
import { ICMPFilterData } from '../typings/api';
import { isCmpScriptNode } from '../utils/base';
import { getNodeValidationData, handleAllCommentedNode, recoveryElement } from '../utils/html';
import { AdlCmpStub } from './api';

let mutationObserver: MutationObserver;

export const filterData: ICMPFilterData = {
  processed: [],
  allowed: []
};

const mutationHandler = (mutationsList: MutationRecord[]) => {
  mutationsList.forEach((mutationRecord) => {
    mutationRecord.addedNodes.forEach((node: HTMLElement) => {
      const _filterData = AdlCmpStub ? AdlCmpStub.filterData : filterData;

      if (CHECK_ELEMENT_LIST.indexOf(node.tagName) !== -1) {
        const { isHostRestricted, isNodeAllowed } = getNodeValidationData(node);

        _filterData.processed.push(node.outerHTML);

        if (isHostRestricted === false || isCmpScriptNode(node)) {
          _filterData.allowed.push(node.outerHTML);

          return;
        }

        if (node.tagName === 'SCRIPT') {
          // for Firefox
          const beforeScriptExecuteListener = function (event: Event) {
            if (node.getAttribute('type') === 'javascript/blocked') {
              event.preventDefault();
            }
            node.removeEventListener('beforescriptexecute', beforeScriptExecuteListener);
          };

          node.addEventListener('beforescriptexecute', beforeScriptExecuteListener);
        }

        if (!isNodeAllowed) {
          if (node.tagName === 'SCRIPT') {
            node.setAttribute('data-origin-type', node['type']);
            node['type'] = 'javascript/blocked';
          }

          node.replaceWith(document.createComment(`${COMMENT_PREFIX}${node.outerHTML}`));
        } else {
          _filterData.allowed.push(node.outerHTML);
        }
      }
    });
  });
};

const startObserve = () => {
  mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true
  });
};

export const init = () => {
  mutationObserver = new MutationObserver(mutationHandler);
  startObserve();
};

export const recoveryMutedElements = (stopObserve = true, force = false) => {
  if (stopObserve) {
    mutationObserver.disconnect();
  }

  handleAllCommentedNode(async (commentNode) => {
    const result = await recoveryElement(commentNode, force);

    if (result && AdlCmpStub) {
      AdlCmpStub.filterData.allowed.push(result.outerHTML);
    }
  });
};
