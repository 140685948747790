interface ISetCookieParams {
  key: string;
  value: string;
  applyOnSubdomains?: boolean;
}

export const setCookie = (params: ISetCookieParams) => {
  document.cookie = `${params.key}=${params.value};path=/;domain=${params.applyOnSubdomains ? '*.' : ''}${
    window.location.hostname
  }`;
};

export const getCookie = (name: string): string | null => {
  const match = document.cookie.match(new RegExp(name + '=([^;]+)'));

  if (match) {
    return match[1];
  }

  return null;
};

export const deleteCookie = (name: string, host?: string) => {
  const expires = new Date(0).toUTCString();
  const domain = host || location.hostname;
  const domainSplitData = domain.split('.');
  let subdomain = domainSplitData.pop();

  document.cookie = name + '=;expires=' + expires;
  document.cookie = name + '=;path=/;expires=' + expires;
  document.cookie = name + '=;Max-Age=0;';

  for (let i = domainSplitData.length - 1; i >= 0; i--) {
    subdomain = domainSplitData[i] + '.' + subdomain;

    document.cookie = name + '=;domain=' + subdomain + ';expires=' + expires;
    document.cookie = name + '=;domain=.' + subdomain + ';expires=' + expires;
    document.cookie = name + '=;path=/;domain=' + subdomain + ';expires=' + expires;
    document.cookie = name + '=;path=/;domain=.' + subdomain + ';expires=' + expires;
  }
};
