import { STORAGE_KEY } from '../constants/STORAGE_KEY';
import { IConsentConfig } from '../typings/lib';
import { ICookiesItem } from '../typings/site-config';
import { getDomainFromCookieHost, getParamsFromUrl } from './string';

export const scriptAttr: Record<string, string> = {
  ...getParamsFromUrl(document.currentScript?.getAttribute('src') || ''),
  ...document.currentScript?.dataset
};

export const getConsentConfig = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEY.ADL_CONSENT) || '{}') as IConsentConfig;
};

export const getAllowedHostListFromCookies = (allowedCategoryList: string[], cookieList: ICookiesItem[]) => {
  const result: string[] = [];

  if (Array.isArray(cookieList) && Array.isArray(allowedCategoryList)) {
    cookieList.forEach((cookieItem) => {
      const host = getDomainFromCookieHost(cookieItem.host);

      if (allowedCategoryList.includes(cookieItem.category.code) && !result.includes(host)) {
        result.push(host);
      }
    });
  }

  return result;
};

export const getAllowedHostListFromVendors = (
  allowedCategoryList: string[],
  vendorsCookieList: Record<string, ICookiesItem[]>,
  allowedVendorList: number[]
) => {
  const result: string[] = [];
  const vendorsIds = Object.keys(vendorsCookieList);

  if (Array.isArray(allowedVendorList) && Array.isArray(allowedCategoryList) && Array.isArray(vendorsIds)) {
    allowedVendorList.forEach((vendorId) => {
      if (Array.isArray(vendorsCookieList[vendorId])) {
        vendorsCookieList[vendorId].forEach((cookieItem) => {
          const host = getDomainFromCookieHost(cookieItem.host);

          if (allowedCategoryList.includes(cookieItem.category.code) && !result.includes(host)) {
            result.push(host);
          }
        });
      }
    });
  }

  return result;
};
